export default [
    {
        key: 'western',
        label: 'Western',
        male: [
            'Alan',
            'Conan',
            'Daveth',
            'Denzel',
            'Derrick',
            'Hammitt',
            'Jermyn',
            'Jory',
            'Merryn',
            'Pasco',
            'Tomas',
            'Trelawney',
            'Tremayne',
            'Tristan',
            'Abel',
            'Abraham',
            'Adam',
            'Alan',
            'Albert',
            'Alex',
            'Alexandre',
            'Alfred',
            'Alphonse',
            'Anatole',
            'André',
            'Anselme',
            'Armand',
            'Arthur',
            'Auguste',
            'Barnabus',
            'Benjamin',
            'Bernard',
            'Bertrand',
            'Blaise',
            'Brice',
            'Bruno',
            'César',
            'Charles',
            'Christian',
            'Christophe',
            'Claude',
            'Cyril',
            'Damien',
            'Dan',
            'Daniel',
            'David',
            'Denis',
            'Didier',
            'Dorian',
            'Edgar',
            'Émile',
            'Emmanuel',
            'Ernest',
            'Ethan',
            'Étienne',
            'Eugène',
            'Fabien',
            'Félix',
            'Ferdinand',
            'Florent',
            'Francis',
            'Frank',
            'Fred',
            'Gabriel',
            'Gaspard',
            'Geoffrey',
            'Georges',
            'Gérard',
            'Gilbert',
            'Guillaume',
            'Gustave',
            'Guy',
            'Hector',
            'Hercule',
            'Horace',
            'Jason',
            'Jean',
            'Jérémie',
            'Jérôme',
            'Jessé',
            'Jonathan',
            'Joseph',
            'Jules',
            'Kevin',
            'Laurent',
            'Léo',
            'Léon',
            'Léonard',
            'Lionel',
            'Lou',
            'Louis',
            'Lucas',
            'Manuel',
            'Marc',
            'Marcel',
            'Martin',
            'Maurice',
            'Maxime',
            'Michaël',
            'Morgan',
            'Nathan',
            'Nicolas',
            'Odilon',
            'Oscar',
            'Pascal',
            'Patrick',
            'Paul',
            'Philippe',
            'Pierre',
            'Raoul',
            'Raphaël',
            'Raymond',
            'Régis',
            'Richard',
            'Robert',
            'Roger',
            'Roland',
            'Ruben',
            'Samson',
            'Samuel',
            'Sébastien',
            'Serge',
            'Simon',
            'Théo',
            'Théodore',
            'Théophile',
            'Thomas',
            'Tristan',
            'Ulysse',
            'Valentin',
            'Victor',
            'Vincent',
            'Xavier',
            'Yves',
            'Abe',
            'Abraham',
            'Adam',
            'Albert',
            'Alex',
            'Alexander',
            'Alfred',
            'Aloysius',
            'Andreas',
            'Anton',
            'Arthur',
            'Augustus',
            'Bart',
            'Ben',
            'Benjamin',
            'Bernhard',
            'Bert',
            'Bob',
            'Bram',
            'Brecht',
            'Casper',
            'Chris',
            'Christiaan',
            'Cornelius',
            'Damian',
            'Daniël',
            'David',
            'Dennis',
            'Dick',
            'Dirk',
            'Ed',
            'Edwin',
            'Erik',
            'Ernst',
            'Erwin',
            'Fabian',
            'Felix',
            'Ferdinand',
            'Filip',
            'Frank',
            'Fred',
            'Frederik',
            'Gerard',
            'Gerhard',
            'Gerrit',
            'Gerry',
            'Gilbert',
            'Gustaaf',
            'Hans',
            'Hendrik',
            'Herman',
            'Hieronymus',
            'Hubert',
            'Hugo',
            'Jacob',
            'Jan',
            'Jasper',
            'Jef',
            'Jesse',
            'Jonas',
            'Jonathan',
            'Jordan',
            'Karel',
            'Kasper',
            'Kevin',
            'Lambert',
            'Lars',
            'Leo',
            'Leonard',
            'Leopold',
            'Levi',
            'Louis',
            'Lucas',
            'Marcel',
            'Marco',
            'Mark',
            'Martin',
            'Max',
            'Michael',
            'Mick',
            'Nick',
            'Nico',
            'Olaf',
            'Oliver',
            'Otto',
            'Pascal',
            'Paul',
            'Peter',
            'Philip',
            'Rembrandt',
            'Richard',
            'Rob',
            'Robert',
            'Robin',
            'Roy',
            'Ruben',
            'Rudolf',
            'Rupert',
            'Rutger',
            'Sebastian',
            'Simon',
            'Stefan',
            'Steven',
            'Sven',
            'Theo',
            'Thomas',
            'Tim',
            'Tom',
            'Vincent',
            'Werner',
            'Willem',
            'Willy',
            'Xander',
            'Adam',
            'Adolf',
            'Arnold',
            'Bern',
            'Baldwin',
            'Conrad',
            'Constantin',
            'Egmund',
            'Eric',
            'Finn',
            'Franco',
            'Gerald',
            'Gregorio',
            'Gunther',
            'Henryk',
            'Herman',
            'Hubert',
            'Isaac',
            'Johannes',
            'Knut',
            'Norbert',
            'Otto',
            'Rainer',
            'Richard',
            'Rupert',
            'Rutger',
            'Simon',
            'Stefan',
            'Waldo',
            'Walther',
            'Willem',
            'akira',
            'goro',
            'haruki',
            'ichiro',
            'jiro',
            'ken',
            'ren',
            'shin',
            'shiro',
            'takashi',
            'Aarón',
            'Abel',
            'Alberto',
            'Albino',
            'Alcides',
            'Alejandro',
            'Alejo',
            'Álex',
            'Alexis',
            'Alfonso',
            'Alfredo',
            'Alonso',
            'Álvaro',
            'Amado',
            'Amador',
            'Anastacio',
            'Andrés',
            'Ángel',
            'Armando',
            'Arsenio',
            'Arturo',
            'Baldo',
            'Bartolomé',
            'Bautista',
            'Benito',
            'Benjamín',
            'Bernardo',
            'Berto',
            'Bolívar',
            'Bonifacio',
            'Bruno',
            'Buenaventura',
            'Carlito',
            'Carlos',
            'Carmelo',
            'César',
            'Che',
            'Chuy',
            'Clemente',
            'Cristóbal',
            'Darío',
            'Diego',
            'Domingo',
            'Edgardo',
            'Eduardo',
            'Eladio',
            'Elías',
            'Emiliano',
            'Emilio',
            'Enrique',
            'Esteban',
            'Evaristo',
            'Fabio',
            'Fabricio',
            'Faustino',
            'Feliciano',
            'Felipe',
            'Félix',
            'Fernando',
            'Fidel',
            'Flavio',
            'Florián',
            'Francisco',
            'Gabriel',
            'Gastón',
            'Geraldo',
            'Gerardo',
            'Gonzalo',
            'Guadalupe',
            'Guillermo',
            'Gustavo',
            'Gutierre',
            'Héctor',
            'Hernán',
            'Hernando',
            'Hugo',
            'Ignacio',
            'Isaac',
            'Isidoro',
            'Jaime',
            'Javier',
            'Jesús',
            'Joaquin',
            'Joel',
            'Jorge',
            'Jose',
            'José Antonio',
            'José Luis',
            'José Manuel',
            'Juan',
            'Juan Carlos',
            'Juan Manuel',
            'Juan Pablo',
            'Julio',
            'Leonardo',
            'Lorenzo',
            'Luciano',
            'Lucio',
            'Luis',
            'Lupe',
            'Manolo',
            'Manuel',
            'Marco',
            'Mariano',
            'Mario',
            'Mateo',
            'Miguel',
            'Nacho',
            'Néstor',
            'Octavio',
            'Óscar',
            'Pablo',
            'Paco',
            'Pancho',
            'Pedro',
            'Pepe',
            'Plácido',
            'Rafael',
            'Ramón',
            'Raúl',
            'Reynaldo',
            'Ricardo',
            'Roberto',
            'Rodrigo',
            'Salvador',
            'Samuel',
            'Sancho',
            'Santos',
            'Sergio',
            'Severino',
            'Silvio',
            'Simón',
            'Tito',
            'Tomás',
            'Valentín',
            'Vicente',
            'Víctor',
            'Wilfredo'
        ],
        female: [
            'Adela',
            'Agatha',
            'Agnes',
            'Ava',
            'Benedicta',
            'Berta',
            'Gertrude',
            'Helena',
            'Olge',
            'Sophia',
            'Susanne',
            'Yolande',
            'Agnes',
            'Albertina',
            'Alexandra',
            'Amanda',
            'Amber',
            'Amelia',
            'Angela',
            'Angelina',
            'Angelique',
            'Anita',
            'Anna',
            'Annabel',
            'Anne',
            'Annemarie',
            'Antonia',
            'Augusta',
            'Beatrix',
            'Brigitta',
            'Carla',
            'Caroline',
            'Catharina',
            'Cecilia',
            'Charlotte',
            'Christina',
            'Christine',
            'Claudia',
            'Cécile',
            'Daphne',
            'Debora',
            'Denise',
            'Diana',
            'Dora',
            'Dorothea',
            'Edith',
            'Elisabeth',
            'Elise',
            'Ellen',
            'Emma',
            'Esther',
            'Eva',
            'Eveline',
            'Felicia',
            'Georgina',
            'Gertie',
            'Gertrude',
            'Hanna',
            'Hannah',
            'Helena',
            'Henrietta',
            'Henriette',
            'Hilda',
            'Hilde',
            'Ida',
            'Irena',
            'Iris',
            'Isabella',
            'Isabelle',
            'Jetta',
            'Johanna',
            'Julia',
            'Juliana',
            'Justine',
            'Karen',
            'Katrina',
            'Lara',
            'Laura',
            'Laurie',
            'Linda',
            'Lisa',
            'Louisa',
            'Louise',
            'Magda',
            'Magdalena',
            'Maria',
            'Marianne',
            'Marilou',
            'Maud',
            'Melissa',
            'Mia',
            'Michelle',
            'Mina',
            'Nicole',
            'Nicolet',
            'Nora',
            'Paula',
            'Petra',
            'Rachel',
            'Rebekka',
            'Renate',
            'Renée',
            'Rosa',
            'Rosanne',
            'Samantha',
            'Sandra',
            'Sara',
            'Saskia',
            'Sophie',
            'Susanna',
            'Tina',
            'Trudy',
            'Ursula',
            'Vanessa',
            'Vera',
            'Willemina',
            'Wilma',
            'Yvonne',
            'Zoë',
            'Eva',
            'Jenna',
            'Gwen',
            'Gwenneth',
            'Gwynne',
            'Jena',
            'Kerra',
            'Lowenna',
            'Merryn',
            'Pasca',
            'Tamsin',
            'Tressa',
            'Adélaïde',
            'Adeline',
            'Agnès',
            'Alberte',
            'Albertine',
            'Alex',
            'Alexandra',
            'Alexandrine',
            'Alexis',
            'Alice',
            'Alix',
            'Anaïs',
            'Anastasie',
            'Angeline',
            'Angélique',
            'Anna',
            'Annabelle',
            'Anne',
            'Annette',
            'Annie',
            'Antoinette',
            'Ariel',
            'Astrid',
            'Augustine',
            'Aurore',
            'Babette',
            'Barbara',
            'Béatrice',
            'Bérénice',
            'Bernadette',
            'Berthe',
            'Blanche',
            'Brigitte',
            'Camille',
            'Caroline',
            'Cassandra',
            'Catherine',
            'Cécile',
            'Céleste',
            'Charlotte',
            'Chloé',
            'Christine',
            'Claire',
            'Clarisse',
            'Claudette',
            'Clémentine',
            'Colette',
            'Constance',
            'Danielle',
            'Daphné',
            'Daphnée',
            'Denise',
            'Diane',
            'Dominique',
            'Édith',
            'Eléonore',
            'Élisabeth',
            'Éloïse',
            'Emma',
            'Emmanuelle',
            'Ernestine',
            'Estelle',
            'Esther',
            'Ève',
            'Eveline',
            'Fanny',
            'Félicité',
            'Fifi',
            'Florence',
            'Florentine',
            'Francine',
            'Francis',
            'Gabrielle',
            'Geneviève',
            'Georgette',
            'Georgine',
            'Gigi',
            'Gilberte',
            'Gwendoline',
            'Hannah',
            'Henriette',
            'Hortense',
            'Irène',
            'Iris',
            'Isabelle',
            'Jacqueline',
            'Jeanette',
            'Jeanine',
            'Jessica',
            'Joanne',
            'Joséphine',
            'Judith',
            'Juliane',
            'Julie',
            'Juliette',
            'Justine',
            'Lili',
            'Lilian',
            'Linda',
            'Louise',
            'Lucille',
            'Madeleine',
            'Margot',
            'Marianne',
            'Marie',
            'Marilou',
            'Marthe',
            'Mélissa',
            'Mélody',
            'Michelle',
            'Modeste',
            'Monique',
            'Muriel',
            'Nadine',
            'Nathalie',
            'Nicole',
            'Nina',
            'Pauline',
            'Pénélope',
            'Rachel',
            'Rébecca',
            'Renée',
            'Rosalie',
            'Rose',
            'Rosette',
            'Roxanne',
            'Sandra',
            'Sara',
            'Sarah',
            'Sophie',
            'Stéphanie',
            'Suzanne',
            'Suzette',
            'Sylvie',
            'Valentine',
            'Violette',
            'Virginie',
            'Wanda',
            'akemi',
            'aki',
            'akiko',
            'akira',
            'aya',
            'chiyo',
            'hana',
            'hanako',
            'haruka',
            'hitomi',
            'kasumi',
            'keiko',
            'mai',
            'mariko',
            'michiko',
            'mitsuko',
            'nana',
            'naomi',
            'natsumi',
            'noriko',
            'ren',
            'rina',
            'sakura',
            'setsuko',
            'yoko',
            'Abigaíl',
            'Adelaida',
            'Adriana',
            'Alexandra',
            'Alexis',
            'Alicia',
            'Alma',
            'Amada',
            'Amanda',
            'América',
            'Ana',
            'Anabel',
            'Ana María',
            'Ana Sofía',
            'Anastasia',
            'Andrea',
            'Ángela',
            'Angélica',
            'Angelina',
            'Anita',
            'Ariel',
            'Aurelia',
            'Beatriz',
            'Berta',
            'Blanca',
            'Camila',
            'Carla',
            'Carmen',
            'Carolina',
            'Clara',
            'Clementina',
            'Conchita',
            'Consuela',
            'Cristina',
            'Dalia',
            'Delia',
            'Diana',
            'Dolores',
            'Dominga',
            'Dora',
            'Dulce',
            'Elena',
            'Elisa',
            'Elodia',
            'Eloísa',
            'Emilia',
            'Emma',
            'Encarnita',
            'Esmeralda',
            'Esperanza',
            'Esther',
            'Eulalia',
            'Eva',
            'Eva María',
            'Evangelina',
            'Evita',
            'Fanny',
            'Fátima',
            'Felicia',
            'Felipa',
            'Flora',
            'Florentina',
            'Fran',
            'Francisca',
            'Gabriela',
            'Georgina',
            'Gertrudis',
            'Gisela',
            'Gloria',
            'Guadalupe',
            'Haydée',
            'Hilda',
            'Inés',
            'Irene',
            'Iris',
            'Irma',
            'Isabela',
            'Isidora',
            'Jenifer',
            'Jennifer',
            'Jenny',
            'Jessica',
            'Johana',
            'Josefina',
            'Juanita',
            'Judith',
            'Julia',
            'Julieta',
            'Justina',
            'Laura',
            'Leandra',
            'Leticia',
            'Liliana',
            'Lola',
            'Lolita',
            'Luna',
            'Lupe',
            'Magdalena',
            'Marcela',
            'Marcia',
            'Margarita',
            'María',
            'María Carmen',
            'María Cristina',
            'María Dolores',
            'María Fernanda',
            'María Guadalupe',
            'María Jesús',
            'María José',
            'María Luisa',
            'María Manuela',
            'Mariana',
            'María Teresa',
            'Marisa',
            'Maritza',
            'Marta',
            'Matilde',
            'Mercedes',
            'Miriam',
            'Modesta',
            'Mónica',
            'Nadia',
            'Natalia',
            'Octavia',
            'Ofelia',
            'Olga',
            'Olivia',
            'Oriana',
            'Paloma',
            'Paola',
            'Patricia',
            'Paula',
            'Paulina',
            'Paz',
            'Perla',
            'Perlita',
            'Pilar',
            'Priscila',
            'Ramona',
            'Raquel',
            'Regina',
            'Remedios',
            'Renata',
            'Rita',
            'Roberta',
            'Rosa',
            'Rosario',
            'Ruth',
            'Sandra',
            'Sara',
            'Selena',
            'Silvia',
            'Sofía',
            'Soledad',
            'Susana',
            'Tamara',
            'Teresa',
            'Úrsula',
            'Valentina',
            'Valeria',
            'Vera',
            'Verónica',
            'Victoria',
            'Violeta',
            'Virginia',
            'Viviana',
            'Yasmina',
            'Yolanda'
        ],
        surname: [
            'Ahearn',
            'Bell',
            'Berryman',
            'Boden',
            'Bray',
            'Brock',
            'Burrows',
            'Connor',
            'Craddick',
            'Crocker',
            'Deane',
            'Drew',
            'Evans',
            'Fry',
            'Gay',
            'Godden',
            'Goldsworthy',
            'Hancock',
            'Hart',
            'Harvey',
            'Hawke',
            'Hoskins',
            'Hutchens',
            'James',
            'Jewell',
            'Johns',
            'Kemp',
            'Kent',
            'Kinsey',
            'Kirby',
            'Lowry',
            'Lean',
            'Lyon',
            'May',
            'Moon',
            'Nance',
            'Nicholls',
            'Oates',
            'Pawley',
            'Perrin',
            'Phillips',
            'Quick',
            'Rickard',
            'Roach',
            'Roberts',
            'Rodgers',
            'Sanders',
            'Symons',
            'Stevens',
            'Thorne',
            'Warren',
            'Franke',
            'Peeters',
            'Aaldenberg',
            'Aalders',
            'Abram',
            'Abrams',
            'Acker',
            'Addens',
            'Adema',
            'Ahlers',
            'Akkerman',
            'Alberda',
            'Alberdink',
            'Albers',
            'Alberts',
            'Alders',
            'Alting',
            'Arntz',
            'Baker',
            'Banner',
            'Beringer',
            'Beulens',
            'Boer',
            'Boon',
            'Bosch',
            'Brams',
            'Brinkerhoff',
            'Carman',
            'Clark',
            'Cuyper',
            'Dahl',
            'Dahlman',
            'De Witte',
            'Dirks',
            'Dreyer',
            'Dykstra',
            'Evers',
            'Franke',
            'Haas',
            'Hansen',
            'Hendrix',
            'Herberts',
            'Herman',
            'Heyman',
            'Holt',
            'Hummel',
            'Jacobs',
            'Jacobson',
            'Jansen',
            'Jansing',
            'Karl',
            'King',
            'Klein',
            'Koning',
            'Krantz',
            'Lucas',
            'Lyon',
            'Michel',
            'Miller',
            'Moore',
            'Nagel',
            'Peeters',
            'Peters',
            'Philips',
            'Richard',
            'Robert',
            'Roosevelt',
            'Samson',
            'Schneider',
            'Schuyler',
            'Schwarzenberg',
            'Seeger',
            'Smith',
            'Snyder',
            'Thomas',
            'Van Aalsburg',
            'Van Buren',
            'Van Der Beek',
            'Van Der Veen',
            'Van Hassel',
            'Van Horn',
            'Van Houte',
            'fujimoto',
            'hayashi',
            'kimura',
            'kurosawa',
            'matsumoto',
            'minami',
            'suzuki',
            'yamaguchi',
            'Babineaux',
            'Barre',
            'Beaufort',
            'Beaumont',
            'Bellamy',
            'Belmont',
            'Berger',
            'Bernard',
            'Blanchard',
            'Bonhomme',
            'Borde',
            'Charpentier',
            'Chevalier',
            'Colbert',
            'Coste',
            'David',
            'Deforest',
            'Delacroix',
            'Desroches',
            'Dubois',
            'Duchamps',
            'Dupont',
            'Fabian',
            'Favreau',
            'Fontaine',
            'Forest',
            'Forestier',
            'François',
            'Garcon',
            'Germain',
            'Giles',
            'Granger',
            'Hardy',
            'Harman',
            'Herbert',
            'Jordan',
            'Labelle',
            'Lachance',
            'Lachapelle',
            'Lane',
            'Lapointe',
            'Larue',
            'Laurent',
            'Lebeau',
            'Leblanc',
            'Leclair',
            'Leroy',
            'Lyon',
            'Marchand',
            'Martel',
            'Martin',
            'Montagne',
            'Mullins',
            'Olivier',
            'Page',
            'Pettigrew',
            'Pierre',
            'Renaud',
            'Robert',
            'Roche',
            'Rose',
            'Roy',
            'Salmon',
            'Samson',
            'Sargent',
            'Sauvage',
            'Segal',
            'Séverin',
            'Simon',
            'Thayer',
            'Thomas',
            'Tolbert',
            'Travers',
            'Tremblay',
            'Vincent',
            'Abel',
            'Abraham',
            'Acosta',
            'Aguilar',
            'Alfaro',
            'Alonso',
            'Alvarado',
            'Álvarez',
            'Amador',
            'Antonio',
            'Antúnez',
            'Armando',
            'Arriola',
            'Asturias',
            'Banderas',
            'Bautista',
            'Bello',
            'Belmonte',
            'Blanco',
            'Bolívar',
            'Bustos',
            'Caballero',
            'Cabrera',
            'Campo',
            'Campos',
            'Carrasco',
            'Castellano',
            'Castilla',
            'Castillo',
            'Castro',
            'Cervantes',
            'Colón',
            'Cortez',
            'Cruz',
            'De la Cruz',
            'De León',
            'Delgado',
            'Del Río',
            'Díaz',
            'Domínguez',
            'Escarrà',
            'Espinosa',
            'Espinoza',
            'Esteban',
            'Estévez',
            'Feliciano',
            'Fernández',
            'Fierro',
            'Figueroa',
            'Flores',
            'Fontana',
            'Franco',
            'Fuentes',
            'García',
            'Gaspar',
            'Giménez',
            'Gómez',
            'Gonzales',
            'González',
            'Gutiérrez',
            'Hernández',
            'Herrera',
            'Hidalgo',
            'Ibarra',
            'Juárez',
            'León',
            'Lopez',
            'Lorenzo',
            'Loyola',
            'Lucas',
            'Luna',
            'Machado',
            'Marino',
            'Márquez',
            'Martín',
            'Martínez',
            'Martinez',
            'Méndez',
            'Mendoza',
            'Molina',
            'Montero',
            'Morales',
            'Moralez',
            'Muñoz',
            'Navarro',
            'Olmos',
            'Ortega',
            'Padilla',
            'Paz',
            'Pérez',
            'Perez',
            'Quesada',
            'Ramírez',
            'Ramos',
            'Reyes',
            'Ríos',
            'Rivera',
            'Rodríguez',
            'Rodriquez',
            'Romero',
            'Rosales',
            'Rosario',
            'Rubio',
            'Ruiz',
            'Salamanca',
            'Salinas',
            'Sánchez',
            'Sanchez',
            'Santana',
            'Santiago',
            'Santos',
            'Sierra',
            'Silva',
            'Torres',
            'Valencia',
            'Vega',
            'Velásquez',
            'Vidal'
        ]
    }
];
